@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
    overflow-x: hidden;
}

.title-font {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

.slider-arrow {
    padding: 0;
    background-color: #0e59f2;
    color: #ffffff;
    border: none;
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 34px;
    border-radius: 99px;
}

.benefits-shadow {
    box-shadow: 0px 1px 2px rgba(32, 33, 36, 0.1);
    border-radius: 16px 16px 16px 16px;
    background-color: #ffffff;
    overflow: hidden;
    transition: 0.3s;
}

.bg-gray {
    background: #ffffff;
}

.error-msg {
    color: red;
}

.text-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.apply-now-btn {
    right: 20px;
    z-index: 99;
    top: 70%;
    position: fixed;
    transform: translateY(-50%);
    border-radius: 30px;
    color: #fff;
    background-color: #2e4388;
    padding: 10px 20px 10px 22px;
    box-shadow: 0 1px 20px 1px #415aac;
    font-size: 16px;
    font-weight: 600;
}

.CTA-Section {
    background: url(../public/assets/cta-bg1.png);
}


.slick-slider .slick-prev,
.slick-slider .slick-next {
    color: #f7f1f1;
    border: none;
    outline: none;
    background: #691432;
    width: 45px;
    border-radius: 50%;
    height: 45px;
    position: absolute;
    z-index: 5;
}

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: 1;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 5px;
    position: absolute;
    left: 3px;
}

.slick-slider .slick-prev:hover,
.slick-slider .slick-prev:focus,
.slick-slider .slick-next:hover,
.slick-slider .slick-next:focus {
    color: white;
    outline: none;
    background: #2e4388;
}

@media (min-width:700px) {
    .home-carousel .slick-slider {
        position: absolute;
        margin-top: 100px;
    }
}

.home-carousel .slick-prev {
    left: 0px;
}

.home-carousel .slick-next {
    right: 0px;
}

.home-carousel .slick-prev,
.home-carousel .slick-next {
    top: 35%;
}


.home-carousel {
    position: relative;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.top-right-corner {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
}

@media (max-width: 768px) {
    .top-right-corner {
        width: 80%;
        right: 10px;
    }
}

.productSlider .slick-slider .slick-prev,
.slick-slider .slick-next {
    color: #f7f1f1;
    border: none;
    outline: none;
    background: #691432;
    width: 45px;
    border-radius: 50%;
    height: 45px;
    position: absolute;
    z-index: 5;
}

.productSlider .slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: 1;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 5px;
    position: absolute;
    left: 3px;
}

.productSlider .slick-prev {
    left: -58px;
}

.productSlider .slick-next {
    right: -58px;
}

.productSlide .slick-slider .slick-slide {
    padding: 0 15px;
}

.right-shadows {
    border: 1px solid #e7e7e7;
}

.bg-shadow {
    box-shadow: 0 14px 59px rgba(0, 0, 0, 0.12);
}

video::-webkit-media-controls {
    display: none;
}

.collapsible {
    background: #26095b;
}

.cardShadow {
    box-shadow: 2px 2px 8px 0px #d0cfcf;
}

.accordion-button {
    font-size: 1.2rem !important;
    color: #000000 !important;
    text-align: left !important;
    background-color: #96bbff !important;
}

.admissionForm label {
    font-weight: 600;
}

.admissionForm input[type="radio"] {
    width: 38px;
    height: 18px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (min-width: 1000px) and (max-width: 1400px) {
    .information-div {
        min-width: 300px;
    }
}

.information-div {
    min-width: 200px;
}

@media (max-width: 1040px) {
    .flex-col {
        flex-direction: column;
    }
}

@media (max-width: 910px) {
    .flex-col {
        flex-direction: column;
    }

    .information-div {
        min-width: 200px;
    }

    .social-icons {
        flex-wrap: wrap;
    }

    .social-icons li {
        flex: 0 0 33.33%;
    }
}

@media (max-width: 600px) {
    .social-icons li {
        flex: 0 0 50%;
    }
}

.flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {
    .flex-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .information-div {
        margin-top: 0;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .flex-container {
        flex-direction: column;
        align-items: center;
    }

    .information-div {
        margin-top: 20px;
    }
}

@media (max-width: 1024px) {
    .section-1 {
        flex-direction: column;
    }

    ;
}

@media (min-width: 1024px) {
    .section-1 {
        flex-direction: row;
    }

    ;
}

.custom-list-item {
    border-bottom: none !important;
    margin-bottom: -15px;
}

.footer-text {
    white-space: nowrap;
}

.footer-span {
    font-size: 12px;
    justify-content: center;
}

@media (max-width: 768px) {
    .footer-logo {
        display: block;
        margin: 0 auto;
    }

    .footer-text {
        margin-top: 15px;
        font-size: 1.25rem;
        text-align: center;
    }

    .footer-span {
        font-size: 10px;
    }
}

@media (max-width: 300px) {
    .footer-text {
        font-size: 1rem;
    }

    .footer-span {
        font-size: 8px;
    }
}


.richText {
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333;
    line-height: 1.5;
}

.richText p {
    margin: 0 0 10px;
}

.richText ul {
    list-style-type: disc;
    margin: 0 0 10px;
}

.richText ol {

    list-style-type: decimal;
    margin: 0 0 10px;
}

.richText li {
    margin-bottom: 5px;
}

.MuiButtonBase-root.MuiTab-root {
    align-items: self-start;
}

.backgroundbannerimage {
    position: relative;
    background-image: url('./images/bannerimage2.jpg');
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-position: center;
    height: 100%;
    min-height: 300px; 
}

.backgroundbannerimage::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    /* background-color: rgba(0, 0, 0, 0.5);  */
    z-index: 1;
}

.backgroundbannerimage > * {
    position: relative;
    z-index: 2; /* Ensure content appears above the overlay */
}

@media (max-width: 768px) {
    .backgroundbannerimage {
        height: auto; /* Adjust the height for smaller screens */
        min-height: 200px; /* Ensure a minimum height for smaller screens */
    }

    .backgroundbannerimage::before {
        height: 100%; /* Ensure the overlay covers the entire area */
    }
}


.background {
    position: relative;
    background-image: url('../public/assets/web.jpg');
    background-size: cover;
    background-position: center;
    height: 100%;
}

.background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1;
}

.background > * {
    position: relative;
    z-index: 2; 
}

@media (max-width: 768px) {
    .background::before {
        height: 100%; 
        min-height: inherit;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 30px;
    z-index: 1; 
}


@media (max-width: 600px) {
    .card-container {
        flex-direction: column;
        align-items: center;
        padding: auto;
        
    }

    .card-container>* {
        margin-bottom: 20px;
     
    }
}

.card {
    width: calc(33.33% - 40px);
    flex: 1;
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
}

.card-image {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
}

.card-paragraph {
    margin-top: 15px;
    text-align: justify;
}

.paragraph-test {
    padding-bottom: 15px;
    text-align: justify;
}

.instruction-container {
    text-align: center;
    margin-bottom: 20px;
    margin-top: -50px;
}


.instruction-heading {
    margin-top: -20px;
}

.instruction-grid {
    align-items: center;
    margin-top: 50px;
}

@media (max-width: 899px) {
    .instruction-grid {
        flex-direction: column;
        margin-top: 50px;
        gap: 0px !important;
    }

    .instruction-icon {
        color: #420097;
        margin-top: 3px;
        margin-left: 0px !important;
    }

    .instruction-text {
        margin-left: -25px;
    }
    .instruction-image {
        margin-left: -50px;
    }
}



.instruction-image {
    width: 140%; 
    height: auto;
    max-width: none; 
}


.reg-form {
    margin-left: 1200px;
}


.register-login-card {
    width: 420px;
    background-color: #ffffff;
    padding: 20px;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    z-index: 10;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .register-login-card {
        width: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
